import React from "react";
import '../src/style.css'
import { useState, useEffect } from 'react';
import { useAccount, usePrepareContractWrite, useContractWrite, useContractReads, useWaitForTransaction, useEnsName } from 'wagmi';
import contractInterface from "../src/config/abi.json";
import {
    useConnectModal,
    useAccountModal
} from '@rainbow-me/rainbowkit';
import { useDebounce } from 'use-debounce';
import Popup from '../src/components/popup'
import $ from "jquery";

function App() {
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const [ChainError, setChainError] = useState(false);
    const [MaxMint, setMaxMint] = useState(false);
    const [UserRejected, setUserRejected] = useState(false);
    const [NotFUNDS, setNotFUNDS] = useState(false);
    const [OnSuccessPopup, setOnSuccessPopup] = useState(false);
    const [DataLoading, setDataLoading] = useState(true);
    const links = {
        Twitter: "https://twitter.com/IRLSpringfield",
        Etherscan: "https://etherscan.io/address/0xa7c11660865b45099218b67d11c18e38b1852934",
        OpenSea: "https://opensea.io/collection/irl-springfield-punks"
    }
    const { isConnected, isDisconnected, isConnecting, address, connector: activeConnector } = useAccount();
    const ensName = useEnsName({
        address: address,
        onSuccess(data) {
            console.log('Success', data)
        },
        onError(error) {
            console.log('ensName', error)
        },
    })
    const toFix = 4;
    const funcName = ['TOTAL_SUPPLY', 'MAX_TX_PER_WALLET', 'PRICE', 'CONTRACT_STATUS', 'MAX_FREE_PER_WALLET', 'totalSupply', 'getTotalMinted', 'balanceOf'];
    const CONTRACT_ADDRESS = "0xa7c11660865b45099218b67d11c18e38b1852934"

    const [isContract, setisContract] = useState({
        TOTAL_SUPPLY: 5555,
        MAX_TX_PER_WALLET: 10,
        PRICE: "5500000000000000",
        CONTRACT_STATUS: "0",
        MAX_FREE_PER_WALLET: 1,
        totalSupply: 0,
        getTotalMinted: 0,
        balanceOf: 0,
    });

    const [inputValue, setinputValue] = useState(isContract.MAX_TX_PER_WALLET);
    const [debounceValue] = useDebounce(inputValue, 500);

    const [Errors, setErrors] = useState({
        INSUFFICIENT_FUNDS: false,
        UNPREDICTABLE_GAS_LIMIT: false,
    })

    useEffect(() => {
        const handleConnectorUpdate = ({ account, chain }) => {
            if (account) {
                console.log('new account', account)
                setDataLoading(true)
            } else if (chain) {
                if (chain.id !== 1) {
                    setChainError(true)
                }
                console.log('new chain', chain)
            }
        }

        if (activeConnector) {
            activeConnector.on('change', handleConnectorUpdate)
        }
    }, [activeConnector])

    const { config: configpbmint, isError: MintError } = usePrepareContractWrite({
        address: CONTRACT_ADDRESS,
        abi: contractInterface,
        functionName: 'publicMint',
        args: [debounceValue],
        overrides: {
            value: Number(isContract.balanceOf) === 0 ? (Number(isContract.PRICE) * (debounceValue - 1)).toString() : (Number(isContract.PRICE) * debounceValue).toString(),
        },
        onError(error) {
            if (error.code === 'INSUFFICIENT_FUNDS') {
                setErrors({ ...Errors, "INSUFFICIENT_FUNDS": true })
            }
            if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
                setErrors({ ...Errors, "UNPREDICTABLE_GAS_LIMIT": true })
            }
            console.log('Error', error.name + " " + error.code)
        },
    })

    const { data: mintData, writeAsync: mint } = useContractWrite({
        ...configpbmint,
        onError(error) {
            if (error.name === 'UserRejectedRequestError') {
                setUserRejected(true)
                setNotFUNDS(false)
            }
            console.log('Error', error.name + " " + error.code)
        },
    })

    const { isLoading: mintLoading } = useWaitForTransaction({
        hash: mintData?.hash,
        onSuccess(data) {
            setOnSuccessPopup(true)
        },
        onError(error) {
            console.log(error.name + " " + error.code)
        },
    })

    useContractReads({
        contracts: [
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[0],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[1],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[2],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[3],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[4],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[5],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[6],
                args: [address],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[7],
                args: [address],
            },
        ],
        watch: true,
        cacheTime: 10000,
        onError(error) {
            console.log('Error', error)
        },
        onSuccess(data) {
            if (isDisconnected) {
                console.log("Disconnected")
            } else {
                console.log('Success', data?.toString())
                let result = {}
                funcName.map((el, i) => {
                    if (data[i] === null) {
                        result[el] = isContract[el.toString()]
                        return true
                    } else {
                        result[el] = data[i]?.toString()
                        return true
                    }
                })
                setisContract(result);
            }
            setDataLoading(false);
        },
    })

    const decrementMintAmount = () => {
        let newMintAmount = inputValue - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setinputValue(newMintAmount);
    }


    const incrementMintAmount = () => {
        let newMintAmount = inputValue + 1;
        if (newMintAmount > Number(isContract.MAX_TX_PER_WALLET - isContract.balanceOf)) {
            newMintAmount = Number(isContract.MAX_TX_PER_WALLET - isContract.balanceOf);
        }
        setinputValue(newMintAmount);
    }


    const clicker = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.header-menu').removeClass('active');
            $('.main-soc').removeClass('active');
            $('body').removeClass('lock');
        }
    }
    const burger = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.header-menu').removeClass('active');
            $('.main-soc').removeClass('active');
            $('body').removeClass('lock');
        } else {
            $('.header__burger').addClass('clicked');
            $('.header__burger').addClass('active');
            $('.mob-menu').addClass('active');
            $('.header-menu').addClass('active');
            $('.main-soc').addClass('active');
            $('body').addClass('lock');
        }
    }


    return (
        <>
            <div className="wbackground">
                <div className="nav">
                    <div className="nav-menu">
                        <div className="main-logo">
                            <img src="img/logo.png" alt="logo" width="173" height="37"/>
                        </div>
                        <div className="menu">
                            <ul className="soc">
                                <li>
                                    <a href={links.OpenSea} target="_blank" rel="noreferrer">
                                        <img src="img/op.svg" alt="Opensea" />
                                    </a>
                                </li>
                                <li>
                                    <a href={links.Etherscan} target="_blank" rel="noreferrer"><img src="img/eth.svg" alt="Etherscan" /></a>
                                </li>
                                <li>
                                    <a href={links.Twitter} target="_blank" rel="noreferrer"><img src="img/x.svg" alt="X" /></a>
                                </li>
                            </ul>
                            <div className="connect btn-hover">
                                {
                                    DataLoading ? [
                                        <>
                                            <p className="unselectable">Loading...</p>
                                        </>
                                    ] : [
                                        <>
                                            {
                                                isConnected && (
                                                    <>
                                                        {ensName.data != null ? [
                                                            <>
                                                                <p className="unselectable" onClick={openAccountModal}>{ensName.data}</p>
                                                            </>
                                                        ] : [
                                                            <>
                                                                <p className="unselectable" onClick={openAccountModal}>{address.substr(0, 4) + '...' + address.substr(-4, 4)}</p>
                                                            </>
                                                        ]}
                                                    </>
                                                )
                                            }
                                            {
                                                isDisconnected && (
                                                    <>
                                                        <p className="unselectable" onClick={openConnectModal}>Connect wallet</p>
                                                    </>
                                                )
                                            }
                                        </>
                                    ]
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mob-menu">
                        <div className="mob-menu__items" onClick={() => clicker()}>
                            {
                                isConnected && (
                                    <>
                                        {ensName.data != null ? [
                                            <>
                                                <div className="header-menu__item wallet">
                                                    <p className="unselectable" onClick={openAccountModal}>{ensName.data}</p>
                                                </div>
                                            </>
                                        ] : [
                                            <>
                                                <div className="header-menu__item wallet">
                                                    <p className="unselectable" onClick={openAccountModal}>{address.substr(0, 4) + '...' + address.substr(-4, 4)}</p>
                                                </div>
                                            </>
                                        ]}
                                    </>
                                )
                            }
                            {
                                isDisconnected && (
                                    <>
                                        <div className="header-menu__item wallet">
                                            <p className="unselectable" onClick={openConnectModal}>Connect</p>
                                        </div>
                                    </>
                                )
                            }
                            <div className="header-soc">
                                <a href={links.Etherscan} target="_blank" rel="noreferrer">
                                    <img src="img/eth.svg" alt="Etherscan Contract" />
                                </a>
                                <a href={links.OpenSea} target="_blank" rel="noreferrer">
                                    <img src="/img/op.svg" alt="Opensea collection" />
                                </a>
                                <a href={links.Twitter} target="_blank" rel="noreferrer">
                                    <img src="/img/x.svg" alt="Twitter" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="header__burger" id="buger" onClick={() => burger()}>
                        <span></span>
                    </div>
                </div>
                <div className="springfield">
                    <div className="springfield-bg">
                        <div className="homer">
                            <img src="img/homer.svg" alt="Homer"/>
                        </div>
                        <div className="marge">
                            <img src="img/marge.svg" alt="Marge"/>
                        </div>
                    </div>
                    <div className="springfield-info">
                        <div className="springfield-about">
                            <div className="logo">
                                <img src="img/logo.png" alt="logo" />
                            </div>
                            <div className="about-text">
                                Introducing the IRL Springfield Punks, our lifeboat to fish out the entire floundering NFT party in the cold winter crypto-ocean, our guiding light through the stormy seas of uncertainty, our lighthouse in the darkness of crypto chaos, our compass in the vast digital wilderness, and our anchor of stability in the turbulent tides of the blockchain. Together, we'll navigate these treacherous waters and lead you to safer shores.
                            </div>
                        </div>
                        <div className="springfield-mint">
                            <div className="mint-info">
                                {
                                    DataLoading ? [
                                        <span>Loading...</span>
                                    ] : [
                                        <>
                                            {
                                                isContract.totalSupply < 9 ? [
                                                    <span> {'000' + isContract.totalSupply + ' / ' + isContract.TOTAL_SUPPLY}</span>
                                                ] : [
                                                    isContract.totalSupply < 99 ? [
                                                        <span> {'00' + isContract.totalSupply + ' / ' + isContract.TOTAL_SUPPLY}</span>
                                                    ] : [
                                                        isContract.totalSupply < 999 ? [
                                                            <span> {'0' + isContract.totalSupply + ' / ' + isContract.TOTAL_SUPPLY}</span>
                                                        ] : [
                                                            <span>{isContract.totalSupply + ' / ' + isContract.TOTAL_SUPPLY}</span>
                                                        ]
                                                    ]
                                                ]
                                            }
                                        </>
                                    ]
                                }
                                <p>First NFT = free</p>
                                <p>{
                                    DataLoading ? [
                                        "Loading..."
                                    ] : [
                                        "Then = " + (Number(isContract.PRICE / 1000000000000000000)).toFixed(toFix) + " ETH"
                                    ]
                                }</p>
                            </div>
                            <div className="mint-btns">
                                <div className="minus unselectable" onClick={decrementMintAmount}>
                                    <img src="img/minus.svg" alt="minus" onClick={decrementMintAmount} className="unselectable" />
                                </div>
                                <div className="count unselectable">
                                    {
                                        inputValue > Number(isContract.MAX_TX_PER_WALLET - isContract.balanceOf) ? [
                                            <p style={{ color: '#EB5757' }}>{inputValue}</p>
                                        ] : [
                                            <p>{inputValue}</p>
                                        ]
                                    }
                                </div>
                                <div className="plus unselectable" onClick={incrementMintAmount}>
                                    {
                                        inputValue > Number(isContract.MAX_TX_PER_WALLET - isContract.balanceOf) ? [
                                            <>
                                                <p style={{ color: '#EB5757' }} onClick={incrementMintAmount}>+</p>
                                            </>
                                        ] : [
                                            <>
                                                <img src="img/plus.svg" alt="plus" onClick={incrementMintAmount} />
                                            </>
                                        ]
                                    }

                                </div>
                            </div>
                            {
                                DataLoading ? [
                                    <div className="mint-buy unselectable btn-hover">
                                        <p className="unselectable">Loading...</p>
                                    </div>
                                ] : [
                                    isConnecting ? [
                                        <div className="mint-buy unselectable btn-hover">
                                            Connecting...
                                        </div>
                                    ] : [
                                        isConnected ? [
                                            isContract.CONTRACT_STATUS === "0" ? [
                                                <div className="mint-buy unselectable btn-hover">
                                                    Sale inactive
                                                </div>
                                            ] : [
                                                <div className="mint-buy unselectable btn-hover" onClick={() => {
                                                    if (MintError) {
                                                        setNotFUNDS(Errors.INSUFFICIENT_FUNDS)
                                                        setMaxMint(Errors.UNPREDICTABLE_GAS_LIMIT)
                                                    } else {
                                                        !mintLoading ? mint() : console.log("Transaction in progress")
                                                    }
                                                }

                                                }>
                                                    {
                                                        mintLoading ? [
                                                            <>
                                                                <p className="unselectable">Minting...</p>
                                                            </>
                                                        ] : [
                                                            <>
                                                                <p className="unselectable">Mint now</p>
                                                            </>
                                                        ]
                                                    }
                                                </div>
                                            ]
                                        ] : [
                                            <div className="mint-buy unselectable btn-hover" onClick={openConnectModal}>
                                                Connect
                                            </div>
                                        ]
                                    ]
                                ]
                            }
                            <div className="buy-info unselectable">
                                {
                                    DataLoading ? [
                                        <span>Loading...</span>
                                    ] : [
                                        <>
                                            {
                                                Number(isContract.balanceOf) === 0 && (Number(inputValue)) <= Number(isContract.MAX_FREE_PER_WALLET) ? [
                                                    inputValue === 1 ? [
                                                        'Total: Free + gas'
                                                    ] : [
                                                        'Total: ' + (Number(isContract.PRICE / 1000000000000000000) * (inputValue - 1)).toFixed(toFix) + ' ETH + gas'
                                                    ]
                                                ] : [
                                                    'Total: ' + (Number(isContract.PRICE / 1000000000000000000) * inputValue).toFixed(toFix) + ' ETH + gas'
                                                ]
                                            }
                                        </>
                                    ]
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <Popup active={OnSuccessPopup} setActive={setOnSuccessPopup} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>Minting complete! It's time to share some positive words on X.</p>
                    </div>
                    <div className="popup-btn-box">
                        <a href="https://twitter.com/intent/tweet?text=just minted my dope IRL Springfield Punks! super stoked to bring that springfield flavor to the blockchain. dive into the punky vibes and let's ride the springfield wave!🤘💎 %0D%0Dirlspringfield.xyz / %23IRLSpringfieldPunks &related=Nakabitos" className='popup-btn btn-hover'  target="_blank" rel="noreferrer">
                            <p style={{ color: "#fff" }}  className="unselectable">Post on X</p>
                        </a>
                        <a href={`https://etherscan.io/tx/${mintData?.hash}`} className='popup-btn' style={{  }} target="_blank" rel="noreferrer">
                            <p style={{ color: "#000" }} className="unselectable">Etherscan</p>
                        </a>
                    </div>
                </div>
            </Popup>
            <Popup active={UserRejected} setActive={setUserRejected} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>You declined the tx. </p>
                    </div>
                </div>
            </Popup>
            <Popup active={MaxMint} setActive={setMaxMint} >
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p> You can only mint up to {Number(isContract.MAX_TX_PER_WALLET - isContract.balanceOf)} NFTs. It looks like you're trying to exceed that limit.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={NotFUNDS} setActive={setNotFUNDS}>
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>Insufficient balance.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={ChainError} setActive={setChainError}>
                <div className="popup-text">
                    <div className="popup-title unselectable">
                        <p>Wrong chain. Please switch to the mainnet for seamless operations.</p>
                    </div>
                </div>
            </Popup>

        </>
    );
}

export default App;
